.home-img {
    max-width: 100%;
  }
  .image {
    display: none;
  }
  .imgWrapper {
    
    width: 50px;
    height: 50px;
  }
  @keyframes blender {
    0% {
      opacity: 0;
      transform: translate(-25px);
    }
    30% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translate(-10px);
    }
  }
  .imageActive {
    float: left;
    width: 10rem;
    height: 13rem;
    object-fit: cover;
    box-sizing: border-box;
    opacity: 0;
    animation: blender 5s linear 50ms;
  }
  .mainanim{
    /* display: flex;
    flex-direction: row; */
    margin-top:-4rem ;
    padding: 0 2rem;
    display: flex;
    flex-direction: row;
    gap: 8rem;
    position: relative;
  }
  @media screen and (max-width:768px){

    .imageActive {
      float: left;
      width: 4rem;
      height: 5rem;
      object-fit: cover;
      box-sizing: border-box;
      opacity: 0;
      animation: blender 5s linear 50ms;
      
    }
    .mainanim{
      /* display: flex;
      flex-direction: row; */
      margin-top:-4rem ;
      padding: 0 2rem;
      display: flex;
      flex-direction: row;
      gap: 2rem;
      position: relative;
    }
    
  }